<template>
    <div class="row g-5 g-xl-8">
        <div class="d-flex justify-content-between">
            <h3 class="text-dark">{{ $t('pages.module.push.title') }}</h3>
            <router-link to="/module" class="btn btn-primary align-self-center ms-3">
                {{ $t("menu.modules") }}
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr024.svg"/>
                </span>
            </router-link>
        </div>
        <el-divider class="mt-3 mb-4"></el-divider>
        <div class="col-xl-3" v-for="(menu, menuIndex) in pushMenus" v-bind:key="menuIndex">
            <div class="card module-card">
                <!--begin::Header-->
                <div class="card-header justify-content-center card-header">
                    <h3 class="card-title text-dark">{{ $t('pages.module.push.menu.' + menu) }}</h3>
                </div>
                <div class="card-body text-center">
                    <router-link :to="getRedirectUrl(menu)">
                        <span class="svg-icon svg-icon-4x svg-icon-dark d-block my-2">
                            <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            redirectUrlPattern: '/module/push/%s',
            customRedirect: {
            },
            pushMenus: ['notification', 'provider']
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.push.title"), [this.$t("menu.moduleManagement")]);
    },
    methods: {
        getRedirectUrl(item) {
            if(this.customRedirect[item]){
                return this.customRedirect[item];
            }

            return this.sprintf(this.redirectUrlPattern, [item.toLowerCase().replace('_', '-')]);
        }
    }
}
</script>

<style>
    .module-card .card-header{
        min-height: 65px;
    }
</style>